@tailwind base;

@layer base {
  @font-face {
    font-family: 'GT Walsheim Pro';
    src: url('/fonts/gt-walsheim-pro-regular-subset.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'GT Walsheim Pro';
    src: url('/fonts/gt-walsheim-pro-medium-subset.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'GT Walsheim Pro';
    src: url('/fonts/gt-walsheim-pro-bold-subset.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  button:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
  }
}

@tailwind components;

@tailwind utilities;
